import React from 'react'
import cardimg from '../../assets/card.png'
import {FaExternalLinkAlt} from 'react-icons/fa'
import {MdOutlineMoreVert} from 'react-icons/md'
import ReactPlayer from 'react-player'

const CardComponent = () => {
  return (
    <>
    <div class="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6 md:tw-gap-8 tw-mt-3">
    <div className='h-100 tw-relative'>
    <div className="position-absolute" style={{right:'3px',top:'5px'}}>
            <div className="btn-group z-3">
                <button type="button" className='border-0 bg-transparent' data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <MdOutlineMoreVert className='fs-4 text-secondary' />
                </button>
                <div className="dropdown-menu">
                    <li className="dropdown-item" >Pin</li>
                    <li className="dropdown-item" >Highlight</li>
                    <li className="dropdown-item">Hero</li>
                    <li className="dropdown-item" >Delete</li>   
                </div>
            </div>
        </div>
        <div className='tw-h-96 tw-overflow-hidden tw-rounded-3xl'>
        <img src={cardimg} alt=" random imgee" class="tw-w-full tw-h-full tw-object-cover tw-object-center tw-rounded-lg tw-shadow-md"/>
        </div>    
     <div class="tw-relative  tw-pr-6 tw--mt-16">
       <div class="tw-bg-white tw-p-4 tw-rounded-3xl tw-rounded-tl-none tw-shadow-lg">
        <h2 class= "tw-block lg:tw-text-xl sm:tw-text-lg poppins tw-font-semibold tw-text-neutral-900">To cool datacenter Servers</h2>
        <span className="tw-block  tw-text-sm lg:tw-text-[16px] tw-mt-3 tw-mb-3">
            <span className="tw-leading-5 tw-text-neutral-500">Nam ultrices, libero non mattis pulvinar, nulla pede ullamcorper augue, a suscipit nulla elit</span>
          </span>
          <div className="tw-flex tw-items-end tw-justify-between tw-mt-auto">
                <span className='tw-text-sm tw-text-neutral-400'>posted: 3min ago</span>
                <span className='tw-mr-4 tw-text-neutral-400'><FaExternalLinkAlt/></span>
          </div>
      </div>
     </div>
    </div>
    <div className='h-100 tw-relative'>
    <div className="position-absolute" style={{right:'3px',top:'5px'}}>
            <div className="btn-group z-3">
                <button type="button" className='border-0 bg-transparent' data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <MdOutlineMoreVert className='fs-4 text-secondary' />
                </button>
                <div className="dropdown-menu">
                    <li className="dropdown-item" >Pin</li>
                    <li className="dropdown-item" >Highlight</li>
                    <li className="dropdown-item">Hero</li>
                    <li className="dropdown-item" >Delete</li>   
                </div>
            </div>
        </div>
        <div className='tw-h-96 tw-overflow-hidden tw-rounded-3xl'>
        <ReactPlayer
        url={'https://youtu.be/e_TxH59MclA?si=XvGha84yRoWtqzlW'}
        className={`absolute bg-neutral-900 inset-0 transition-opacity`}
        width="100%"
        height="100%"
        controls
      />
        </div>    
     <div class="tw-relative  tw-pr-6 tw--mt-16">
       <div class="tw-bg-white tw-p-4 tw-rounded-3xl tw-rounded-tl-none tw-shadow-lg">
        <h2 class= "tw-block lg:tw-text-xl sm:tw-text-lg poppins tw-font-semibold tw-text-neutral-900">To cool datacenter Servers</h2>
        <span className="tw-block  tw-text-sm lg:tw-text-[16px] tw-mt-3 tw-mb-3">
            <span className="tw-leading-5 tw-text-neutral-500">Nam ultrices, libero non mattis pulvinar, nulla pede ullamcorper augue, a suscipit nulla elit</span>
          </span>
          <div className="tw-flex tw-items-end tw-justify-between tw-mt-auto">
                <span className='tw-text-sm tw-text-neutral-400'>posted: 3min ago</span>
                <span className='tw-mr-4 tw-text-neutral-400'><FaExternalLinkAlt/></span>
          </div>
      </div>
     </div>
    </div>
    <div class="card tw-bg-white tw-border-none tw-mt-3 tw-rounded-3xl tw-shadow-lg h-100">
        <div className=' tw-flex tw-justify-center tw-items-center tw-flex-col tw-h-full tw-p-4'>
          <h2 class="tw-block lg:tw-text-4xl sm:tw-text-lg poppins tw-font-semibold tw-text-neutral-900">
              Noteworthy technology acquisitions 2021
          </h2>
          <span class="tw-leading-5 tw-text-neutral-500 tw-block  tw-text-sm lg:tw-text-[16px] tw-mt-3">
              Here are the biggest enterspanrise technology acquisitions of 2021 so far, in reverse chronological order.
          </span>
          </div>
          <div className="card-footer tw-bg-transparent  tw-p-4 tw-border-none tw-flex tw-items-end tw-justify-between tw-mt-auto">
                <span className='tw-text-sm tw-text-neutral-400'>posted: 3min ago</span>
                <span className='tw-mr-4 tw-text-neutral-400'><FaExternalLinkAlt/></span>
          </div>
      </div>
</div>



<div className="tw-grid sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-6 md:tw-gap-8 tw-mt-3 lg:tw-mt-3 tw-mb-5">

<div className="card tw-bg-white tw-border-none tw-mt-3 tw-rounded-3xl tw-shadow-lg dark:tw-bg-gray-800 dark:tw-border-gray-700 h-100">
          <div className="tw-overflow-hidden tw-cursor-pointer tw-h-64 tw-rounded-t-3xl tw-rounded-b-none">        
          <img className="tw-w-full tw-h-full tw-object-cover tw-object-center " src={cardimg} alt='computer'/>
           </div>
           <div className="tw-p-4">
                <h2 class= "tw-block lg:tw-text-xl sm:tw-text-lg poppins tw-font-semibold tw-text-neutral-900">To cool datacenter Servers</h2>
                <span className="tw-block  tw-text-sm lg:tw-text-[16px] tw-mt-3 tw-mb-3">
                  <span className="tw-leading-5 tw-text-neutral-500">Nam ultrices, libero non mattis pulvinar, nulla pede ullamcorper augue, a suscipit nulla elit</span>
                </span>
           </div>
           <div className="card-footer tw-bg-transparent tw-border-none tw-bottom-0 tw-p-4 tw-flex tw-items-end tw-justify-between tw-mt-auto">
                <span className='tw-text-sm tw-text-neutral-400'>posted: 3min ago</span>
                <span className='tw-mr-4 tw-text-neutral-400'><FaExternalLinkAlt/></span>
          </div>
       </div>
<div className="tw-bg-white tw-mt-3 tw-rounded-3xl tw-shadow-lg dark:tw-bg-gray-800 dark:tw-border-gray-700 h-100">
          <div className="tw-overflow-hidden tw-cursor-pointer tw-h-64 tw-object-cover tw-object-center tw-rounded-t-3xl tw-rounded-b-none">        
          <ReactPlayer
              url={'https://youtu.be/e_TxH59MclA?si=XvGha84yRoWtqzlW'}
              className={`absolute bg-neutral-900 inset-0 transition-opacity`}
              width="100%"
              height="100%"
              controls
            />
           </div>
           <div className="tw-p-4">
                <h2 class= "tw-block lg:tw-text-xl sm:tw-text-lg poppins tw-font-semibold tw-text-neutral-900" style={{fontSize:''}}>To cool datacenter Servers</h2>
                <span className="tw-block  tw-text-sm lg:tw-text-[16px] tw-mt-3 tw-mb-3">
                  <span className="tw-leading-5 tw-text-neutral-500">Nam ultrices, libero non mattis pulvinar, nulla pede ullamcorper augue, a suscipit nulla elit  suscipit nulla elit  suscipit nulla elit  suscipit nulla elit  suscipit nulla elit  suscipit nulla elit</span>
                </span>
           </div>
           <div className="tw-p-4 tw-flex tw-items-end tw-justify-between tw-mt-auto">
                <span className='tw-text-sm tw-text-neutral-400'>posted: 3min ago</span>
                <span className='tw-mr-4 tw-text-neutral-400'><FaExternalLinkAlt/></span>
          </div>
       </div>


       <div className="tw-bg-white tw-mt-3 tw-rounded-3xl tw-shadow-lg h-100">
          <div className="tw-overflow-hidden tw-cursor-pointer tw-h-full tw-rounded-3xl">        
          <ReactPlayer
              url={'https://youtu.be/e_TxH59MclA?si=XvGha84yRoWtqzlW'}
              className={`absolute bg-neutral-900 inset-0 transition-opacity`}
              width="100%"
              height="100%"
              controls
            />
           </div>
       </div>



       <div class="card tw-bg-white tw-border-none tw-mt-3 tw-rounded-3xl tw-shadow-lg">
        <div className=' tw-flex tw-justify-center tw-items-center tw-flex-col tw-h-full tw-p-4'>
          <h2 class="tw-block lg:tw-text-4xl sm:tw-text-lg poppins tw-font-semibold tw-text-neutral-900">
              Noteworthy technology acquisitions 2021
          </h2>
          <span class="tw-leading-5 tw-text-neutral-500 tw-block  tw-text-sm lg:tw-text-[16px] tw-mt-3">
              Here are the biggest enterspanrise technology acquisitions of 2021 so far, in reverse chronological order.
          </span>
          </div>
          <div className="card-footer tw-bg-transparent  tw-p-4 tw-border-none tw-flex tw-items-end tw-justify-between tw-mt-auto">
                <span className='tw-text-sm tw-text-neutral-400'>posted: 3min ago</span>
                <span className='tw-mr-4 tw-text-neutral-400'><FaExternalLinkAlt/></span>
          </div>
      </div>


</div>



</>
  )
}

export default CardComponent