import React, { useEffect, useState } from 'react';
import profile from '../../assets/profile.png';
import PostBox from '../PostBox/PostBox';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../assets/logofull.png';

const Navbar = () => {
    const [data, setData] = useState(null);
    const [pageList, setPageList] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [selectedPageId , setSelectedPageId] = useState(null)
    const location = useLocation();
    const match = location.pathname.match(/^\/post\/(\d+)$/);
    const pageid = match ? match[1] : null;
    const push = useNavigate();

    useEffect(() => {
        const getData = JSON.parse(localStorage.getItem('userData'));

        if (getData) {
            setData(getData);

            const fetchData = async () => {
                const res = await axios.get(`https://server.theatom.blog/api/dashboard/${getData.uid}`);
                setPageList(res.data);

                // Retrieve the selected page title from local storage
                const storedSelectedPage = localStorage.getItem('selectedPage');
                const storedSelectedId = localStorage.getItem('selectedPageId')
                // If there's a selected page title stored, set it as the selected page
                if (storedSelectedPage && storedSelectedId) {
                    setSelectedPage(storedSelectedPage);
                    setSelectedPageId(storedSelectedId)
                } else {
                    // If no selected page title stored, default to the first page
                    setSelectedPage(res.data.length > 0 ? res.data[0].page_title : null);
                    setSelectedPageId(res.data.length > 0 ? res.data[0].pid : null)
                }
            };

            fetchData();
        }
    }, []);

    const handleClick =  (pageTitle, pid) => {
        setSelectedPage(pageTitle);
        setSelectedPageId(pid) // Update selected page
        // Save the selected page title and pid in local storage
         localStorage.setItem('selectedPage', pageTitle);
         localStorage.setItem('selectedPageId', pid);
    };

    const handleLogout = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('selectedPage'); // Remove selected page from local storage
        localStorage.removeItem('selectedPageId'); // Remove selected page ID from local storage
        push('/');
        window.location.reload(false);
    };

    return (
        <nav className="navbar navbar-expand-lg shadow-sm rounded d-flex justify-content-between">
            <img src={logo} alt="logo" className="p-3" style={{ width: '70px' }} />
            <div className="d-flex align-items-center mx-3 mx-sm-5">
                {pageList && pageList.length > 0 ? (
                    <div className="btn-group">
                        {pageid ? (
                            <button className="btn btn-none border-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectedPage || (pageList[0] && pageList[0].page_title)}
                            </button>
                        ) : null}
                        <ul className="dropdown-menu text-dark">
                            {pageList
                                ? pageList.map(item => (
                                      <li key={item.pid}>
                                          <Link
                                              to={`/post/${item.pid}`}
                                              className="dropdown-item"
                                              onClick={() => handleClick(item.page_title, item.pid)}
                                          >
                                              {item.page_title}
                                          </Link>
                                      </li>
                                  ))
                                : null}
                            <Link to="/createPage" className="dropdown-item">
                                New Page
                            </Link>
                        </ul>
                    </div>
                ) : null}
                {pageid ? <PostBox /> : null}
                {pageid ? (
                    <div className="btn-group">
                        <button className="btn btn-none border-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {pageid ? <img src={data ? data.user_image : profile} className="object-fit-contain rounded-circle mx-2" alt="profile" style={{ width: '35px', height: '35px' }} /> : null}
                        </button>
                        <ul className="dropdown-menu text-dark">
                            <li>
                                <Link className="dropdown-item" to={`/createProfile/${selectedPageId}`}>
                                    Page Details
                                </Link>
                            </li>
                            <li className="dropdown-item" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                                Logout
                            </li>
                        </ul>
                    </div>
                ) : null}
            </div>
        </nav>
    );
};

export default Navbar;
