import React, { useState, useEffect } from 'react';
import Navbar from './Components/Navbar/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter } from 'react-router-dom';
import AllRoutes from './AllRoutes/AllRoutes';
import './index.css';
import './App.css';
import './Colors.css';

const App = () => {
  const isEndUserPage = window.location.pathname.match(/^\/\w+$/);

  const [isHomePage, setIsHomePage] = useState(
    window.location.pathname === '/' ||
      window.location.pathname === '/auth' ||
      window.location.pathname === '/createPage' ||
      window.location.pathname.startsWith('/dashboard') ||
      window.location.pathname.match(/^\/dashboard\/\d+$/) ||
      window.location.pathname.startsWith('/createProfile') ||
      window.location.pathname.match(/^\/createProfile\/\d+$/)
  );

  const [backgroundStyle, setBackgroundStyle] = useState({
    background:
      'linear-gradient(0deg, rgba(255,222,89,0.7077424719887955) 12%, rgba(255,255,255,1) 89%)',
    minHeight: '100vh',
  });

  useEffect(() => {
    let isMounted = true;

    const updateBackgroundStyle = () => {
      const themepage =
        window.location.pathname.startsWith('/dashboard') ||
        window.location.pathname.match(/^\/dashboard\/\d+$/) ||
        window.location.pathname.startsWith('/createProfile') ||
        window.location.pathname.match(/^\/createProfile\/\d+$/);

      const newIsHomePage =
        window.location.pathname === '/' ||
        window.location.pathname === '/auth' ||
        window.location.pathname === '/createPage' ||
        themepage;

      const newBackgroundStyle = newIsHomePage
        ? {
            background:
              'linear-gradient(0deg, rgba(255,222,89,0.7077424719887955) 12%, rgba(255,255,255,1) 89%)',
            minHeight: '100vh',
          }
        : {
            background: 'white',
            minHeight: '100vh',
          };

      if (isMounted) {
        setIsHomePage(newIsHomePage);
        setBackgroundStyle(newBackgroundStyle);
      }
    };

    const intervalId = setInterval(updateBackgroundStyle, 100);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, []); // Run the effect only once on mount

  return (
    <div className='px-4 py-2 font-body' style={backgroundStyle}>
      <BrowserRouter>
        {!(isHomePage || isEndUserPage) ? <Navbar /> : null}
        <AllRoutes />
      </BrowserRouter>
    </div>
  );
};

export default App;
