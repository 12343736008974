import React, { useEffect, useState } from 'react'
import PostBody from './PostBody'
import PostFooter from './PostFooter'
import PostImageSelect from './PostImageSelect'
import axios from 'axios'
import ReactPlayer from 'react-player'



const PostBoxModal = () => {

  const [heading,setHeading] = useState(false)
  const [postTitle,setPostTitle] = useState(null);
  const [postUrl,setPostUrl] = useState(null);
  const [ytUrl,setYtUrl] = useState(null);
  const [imageUrl,setImageUrl] = useState(null);
  const [inputvalue,setInputvalue] = useState(null);
  const [isScheduled, setIsScheduled] = useState(false);
  const [startDate,setStartdate] = useState()
  // const timeStamp = new Date().toISOString().slice(0, 19).replace('T', ' ');
  const pathArray = window.location.pathname.split('/');
  const pageId = pathArray[pathArray.length-1];



  const now = new Date();
  const timezoneOffset = now.getTimezoneOffset(); // Get the timezone offset in minutes
  now.setMinutes(now.getMinutes() - timezoneOffset); // Adjust for the timezone offset
  const timeStamp = now.toISOString().slice(0, 19).replace('T', ' ');

  console.log(timeStamp);




  const toggleHeading = ()=>{
    setHeading(!heading)
  }
  
  const handleInputChange = (e)=>{
    const newValue = e.target.value;

    if(newValue.length <= 200){
      setInputvalue(newValue)
    }
  }

  const submitPost = async (e) =>{
      e.preventDefault()
      const data  = await {pid:pageId,postTitle,postBody:inputvalue,postUrl,ytUrl,timeStamp,imageUrl};
      console.log(data)

      if((postTitle || inputvalue) || (imageUrl || ytUrl)){
      try{  
      const res = await axios.post('https://server.theatom.blog/api/createPost',data);
      if(res){
        console.log(res);
        window.location.reload();
      }
      }
      catch(err){
        console.log(err);
      }
    }
  }




const schedulePost = async (scheduleDate) => {
  setStartdate(scheduleDate)
  const currentDate = new Date();
  if (currentDate >= scheduleDate) {
    if (!isScheduled) {
      setIsScheduled(true);
      const data  = {pid:pageId,postTitle,postBody:inputvalue,postUrl,ytUrl,timeStamp,imageUrl};
      console.log(data)

      if((postTitle || inputvalue) || (imageUrl || ytUrl)){
      try{  
      const res = await axios.post('https://server.theatom.blog/api/createPost',data);
      if(res){
        console.log(res);
        window.location.reload();
      }
      }
      catch(err){
        console.log(err);
      }
    }
    }
  }
}

useEffect(() => {
  if (isScheduled) {
    
    return;
  }


  const intervalId = setInterval(() => {
    schedulePost(startDate);
  }, 2000);

  return () => {
    clearInterval(intervalId); 
  };
}, [isScheduled, startDate]);



 
  return (
    <>
    <div className="modal fade" id="exampleModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" style={{ background:'linear-gradient(0deg, rgba(255,222,89,0.7077424719887955) 12%, rgba(255,255,255,1) 89%)',
}}>
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content shadow-lg" style={{borderBottomWidth:'10px',borderTopWidth:'10px',borderLeftWidth:'10px',borderRightWidth:'10px',borderColor:'rgba(0, 0, 0, 0.05)',borderStyle:'solid',backdropFilter:'blur(10px)',borderRadius:'36px'}}>
        <div className="modal-header p-3 tw-border-none tw-flex tw-justify-center tw-items-center">
          {(imageUrl || ytUrl) ? <div className='tw-w-[200px] tw-h-[200px tw-rounded-3xl'>
            {imageUrl?<img className="tw-w-full tw-h-full tw-object-cover tw-object-center " src={imageUrl} alt='computer'/>:<ReactPlayer url={ytUrl} className={`tw-w-full tw-h-full tw-object-cover tw-object-center `} width="100%" height="100%" controls/> }
          </div>:null}
        </div>
        <div className="modal-body">
          <PostBody visible={heading} inputValue={inputvalue} handleInputChange={handleInputChange} setPostTitle={setPostTitle}/>
        </div>
        <div className="modal-footer d-flex justify-content-between align-items-center p-2 tw-bg-[#f2f2f2] tw-rounded-b-[24px] tw-border-none">
         <PostFooter toggle={toggleHeading} setPostUrl={setPostUrl} submitPost={submitPost} schedulePost={schedulePost} setYtUrl={setYtUrl} />
        </div>
      </div>
    </div>
  </div>
    <PostImageSelect setImageUrl={setImageUrl}/>
  </>
  )
}

export default PostBoxModal