import React, { useEffect, useState } from 'react'
import PageLists from './PageLists';
import { useNavigate,Link } from 'react-router-dom';
import logo from '../../assets/logofull.png'

const Dashboard = () => {

  const push = useNavigate()

  useEffect(()=>{
    const checkUserExist = async () =>{
      const userData =await JSON.parse(localStorage.getItem('userData'));
      if(!userData){
          push('/')
      }
    }

    checkUserExist();
  })


  return (
    <>
    <Link to='/'><img src={logo} alt="logo" className='p-3 tw-w-28'/></Link>
    <div className='d-flex justify-content-center align-items-center' style={{height:'80vh'}}>
          <PageLists/>
    </div>
    </>
  )
}

export default Dashboard