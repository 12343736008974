import React, { useEffect } from 'react'
import {Routes,Route, useNavigate } from 'react-router-dom'
import LandingPage from '../Components/LandingPage/LandingPage'
import Auth from '../Components/Auth/Auth'
import Dashboard from '../Components/Dashboard/Dashboard'
import CreatePage from '../Components/PostBox/CreatePage'
import EndUser from '../Components/EndUser/EndUser'
import CreateProfile from '../Components/LandingPage/CreateProfile'
import Settings from '../Components/LandingPage/Settings'
import CardComponent from '../Components/LandingPage/CardComponent'
// import NewCard from '../Components/LandingPage/NewCard'




const AllRoutes = () => {
  const push = useNavigate()
  const isEndUserPage = window.location.pathname.match(/^\/\w+$/);

  useEffect(()=>{
    const getData = JSON.parse(localStorage.getItem('userData'));

    if(getData && !isEndUserPage ){
        push(`/dashboard/${getData.uid}`)
    }
},[])
  

  return (
   
        <Routes>
            <Route path='/' element={<Auth/>}></Route>
            {/* <Route path='/auth' element={<Signin/>}></Route> */}
            <Route path='/cardcomponent' element={<CardComponent/>}></Route>
            <Route path='/dashboard/:userId' element={<Dashboard/>}></Route>
            <Route path='/post/:pageId' element={<LandingPage/>}></Route>
            {/* <Route path='/newpost/:pageId' element={<NewCard/>}></Route> */}
            <Route path='/createPage' element={<CreatePage/>}></Route>
            <Route path='/createProfile/:pageId' element={<CreateProfile/>}></Route>
            <Route path='/settings/:pageId' element={<Settings/>}></Route>
            <Route path='/:pageName' element={<EndUser/>}></Route>
        </Routes>
   
  )
}

export default AllRoutes