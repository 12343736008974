import React, { useEffect, useState } from 'react'
import PostSection from './PostSection'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import PageDetails from './PageDetails'

const LandingPage = () => {
    
   const [hero,setHero] = useState(false);
   const [profile,setProfile] = useState(false);
   const [title,setTitle] = useState('')
   const [open,setOpen] = useState(false)
   const {pageId} = useParams()

   const [pageTitle, setPageTitle] = useState('');
    const [cardsPerRow, setCardsPerRow] = useState(4);
    const [showHero, setShowHero] = useState(true);
    const [showProfile, setShowProfile] = useState(true);
    const [flatCard, setFlatCard] = useState(false); 
    const [timeStamp, setTimeStamp] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`https://server.theatom.blog/api/getPageSettings/${pageId}`);
                if (res.data) {
                    setPageTitle(res.data.page_title);
                    setCardsPerRow(parseInt(res.data.cards_per_row));
                    setShowHero(res.data.hero === '1');
                    setShowProfile(res.data.profile === '1');
                    setFlatCard(res.data.flat_card === '1');
                    setTimeStamp(res.data.time_stamp === '1');
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [pageId]);

    useEffect(()=>{
        const fetchPageTitle = async () =>{
            try {
              const res = await axios.get(`https://server.theatom.blog/api/getPageTitle/${pageId}`);
              if(res){
                setTitle(res.data.page_title)
              }
            } catch (error) {
              console.log(error)  
            }
          }


          fetchPageTitle();

    },[pageId])

    // const handleSaveClick = async () => {
    //             const res = await axios.post('https://server.theatom.blog/api/updatePage',{pid:pageId,pageTitle})
    //             if(res){
    //                 console.log(res.data);
    //             }
    //       };

    const handleClick = async () => {
        const updatedSettings = {
            pid: pageId,
            cardsPerRow: cardsPerRow,
            hero: showHero ? true : false,
            profile: showProfile ? true : false,
            flatCard: flatCard ? true : false,
            timeStamp: timeStamp ? true : false
        };

        try {
            const res = await axios.post('https://server.theatom.blog/api/createPageSetting', updatedSettings);
            if (res) {
                console.log(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    };



  useEffect(()=>{
      const fetchData = async ()=>{
        const res = await axios.get(`https://server.theatom.blog/api/getPageSettings/${pageId}`)
        if(res.data){
            setHero(res.data.hero === '1')
            setProfile(res.data.profile === '1')
        }
      }
      fetchData();    
  },[pageId])

  const toggle = () =>{
    setOpen(!open)
  }


  return (
    <div className='cotainer-fluid py-3 tw-relative'>
      <button class="tw-p-2.5 tw-bg-[#ffde59] tw-rounded-xl tw-shadow-xl tw-border-none tw-z-20 tw-fixed tw-right-4 tw-top-36" type="button" aria-expanded="true" data-headlessui-state="open" id="headlessui-popover-button-:Rlda:" aria-controls="headlessui-popover-panel-:r5d:" onClick={()=>toggle()}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="tw-w-8 tw-h-8"><path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"></path><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg></button>

      { open && <div className='card tw-p-2 tw-bg-white tw-rounded-3xl tw-shadow-xl tw-border-none tw-z-10 tw-fixed tw-right-4 tw-top-52 tw-w-[300px]'>
            <div className="card-header bg-body p-1">
                <h2 className='lg:tw-text-xl sm:tw-text-sm tw-text-neutral-900' style={{fontFamily:'poppins'}}>Settings</h2>
            </div>
            <div className="card-body">
            
           <div className="form-group col-12 tw-mb-4">
                <label><h6 className='tw-text-[16px]' style={{fontFamily:'poppins'}}>No. of Cards per Row</h6></label>
                <div className='tw-flex tw-justify-start tw-items-center tw-gap-4'>
                <div className="form-check">
                    <input
                        type="radio"
                        className="form-check-input"
                        id="cardsPerRow3"
                        name="cardsPerRow"
                        value={3}
                        checked={cardsPerRow === 3}
                        onChange={() => setCardsPerRow(3)}
                    />
                    <label className="form-check-label" htmlFor="cardsPerRow3">3</label>
                </div>
                <div className="form-check">
                    <input
                        type="radio"
                        className="form-check-input"
                        id="cardsPerRow4"
                        name="cardsPerRow"
                        value={4}
                        checked={cardsPerRow === 4}
                        onChange={() => setCardsPerRow(4)}
                    />
                    <label className="form-check-label" htmlFor="cardsPerRow4">4</label>
                </div>
                </div>
            </div>
            <div className='form-group col-12 d-flex tw-mb-4'>
                <h6 className='tw-text-neutral-900 tw-text-[16px] tw-mr-3' style={{fontFamily:'poppins'}}>Flat Card</h6>
                <label class="tw-relative tw-inline-flex tw-items-center tw-cursor-pointer">
                <input type="checkbox" value="" className="tw-sr-only tw-peer" checked={flatCard} onChange={() => setFlatCard(!flatCard)}/>
                <div class="tw-w-11 tw-h-6 tw-bg-gray-200 tw-rounded-full tw-peer peer-focus:tw-ring-4 peer-focus:tw-ring-blue-300 peer-checked:after:tw-translate-x-full rtl:peer-checked:after:tw--translate-x-full peer-checked:after:tw-border-white after:tw-content-[''] after:tw-absolute after:tw-top-0.5 after:tw-start-[2px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-5 after:tw-w-5 after:tw-transition-all peer-checked:tw-bg-blue-600"></div>
                </label>
            </div>
            <div className='form-group col-12 6 d-flex tw-mb-2'>
                <h6 className='tw-mr-3 tw-text-neutral-900 tw-text-[16px]' style={{fontFamily:'poppins'}}>Time Stamp</h6>
                <label class="tw-relative tw-inline-flex tw-items-center tw-cursor-pointer">
                <input type="checkbox" value="" className="tw-sr-only tw-peer" checked={timeStamp} onChange={() => setTimeStamp(!timeStamp)}/>
                <div class="tw-w-11 tw-h-6 tw-bg-gray-200 tw-rounded-full tw-peer peer-focus:tw-ring-4 peer-focus:tw-ring-blue-300 peer-checked:after:tw-translate-x-full rtl:peer-checked:after:tw--translate-x-full peer-checked:after:tw-border-white after:tw-content-[''] after:tw-absolute after:tw-top-0.5 after:tw-start-[2px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-5 after:tw-w-5 after:tw-transition-all peer-checked:tw-bg-blue-600"></div>
                </label>
            </div>
            </div>
            <div className="card-footer tw-w-full bg-body tw-border-none p-2">
            <button className="btn btn-danger mx-2 tw-w-full tw-bg-[#ffde59] tw-text-neutral-900 tw-border-none hover:tw-bg-yellow-400 hover:tw-text-black tw-mb-2" onClick={handleClick} style={{fontFamily:'poppins'}}>
             Save
             </button>
             <a href = {`/${title}`} target='_blank' className='tw-text-sm tw-mx-2 tw-text-blue-600 tw-italic'>Preview your page</a>
             </div>
        </div>}
    <div className="row">
        <div className='col-12 mt-2'>
            <PageDetails/>
        </div>
        <div className='col-12 mt-2'>
            <PostSection/>
        </div>
    </div>
</div>
  )
}

export default LandingPage


 {/* {hero && <div className="col-lg-9 col-md-8 col-sm-12">
            <Hero/>
        </div>}
      {profile && <div className="col-lg-3 col-md-4 col-sm-12">
            <Profile/>
        </div>} */}

        {/* <div className='form-group'>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Page Title"
                    value={pageTitle}
                    onChange={(e) => setPageTitle(e.target.value)}
                />
                <button className="btn btn-danger mx-2" onClick={handleSaveClick}>
                    Save
                </button>
            </div> */}