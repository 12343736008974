import React, { useEffect, useState } from 'react'
import Card from './Card'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './PostSection.css'
import CardThree from './CardThree';

const PostSection = () => {


  const { pageId } = useParams();
  const [cardsPerRow,setCardsPerRow] = useState(4);
  useEffect(() => {
    const fetchData = async () => {
        try {
            const res = await axios.get(`https://server.theatom.blog/api/getPageSettings/${pageId}`);
            if (res.data) {
                setCardsPerRow(parseInt(res.data.cards_per_row));
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Initial call to fetchData
    fetchData();

    // Set up the interval to call fetchData every 2 seconds
    const fetchDataInterval = setInterval(() => {
        fetchData();
    }, 1000);

    // Clean up the interval when the component is unmounted or when pageId changes
    return () => clearInterval(fetchDataInterval);

}, [pageId]); // Add any dependencies from your useEffect


    const actualCardsPerRow = cardsPerRow || 4;

  return (
   <div className={`row row-cols-1 row-cols-md-2 row-cols-lg-${actualCardsPerRow} justify-content-start g-4 mb-2`}>
        {actualCardsPerRow === 4 ? <Card/> : <CardThree/>}
     </div>

  )
}

export default PostSection