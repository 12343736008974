import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios';

const PageLists = () => {

    const [data,setData] = useState(false);
    const [pageTitle,setPageTitle] = useState(null);
    const [pageDescription,setPageDescription] = useState(null)
    const [error,setError] = useState(false);
    const {userId} = useParams();
    const push = useNavigate();
    const storedSelectedId = localStorage.getItem('selectedPageId')

    useEffect(()=>{

        const fetchData = async () =>{

        const res = await axios.get(`https://server.theatom.blog/api/dashboard/${userId}`);
        
        if(res.data.message){
            setData(true)
        }
         else if(res.data){
            console.log(res)
            push(`/post/${storedSelectedId ? storedSelectedId : res.data[0].pid}`)
        }
        else{
            console.log('error')
        }
        }
        fetchData();
        
    },[userId])

    const createNewpage = async () =>{
      
      if(!pageTitle){
        setError(true);   
    }
    else{
      try{
        const res = await axios.post(`https://server.theatom.blog/api/createPage`,{pageTitle,uid:userId,pageDescription})
        if(res){
          push(`/post/${res.data.pid}`)
          window.location.reload()
        }
      }
      catch(err){
        console.log(err);
      }
    }
    }


  return (
    
    <>
      {!data?
      <div  className='tw-flex tw-justify-center tw-items-center tw-flex-col tw-h-screen tw-w-screen'>
      <HashLoader/>
      <p className='fs-6 mt-3'>Please wait while we fetch your page</p>
      </div>
      :
      <div className='col shadow-lg p-4 col-12 col-md-6 col-lg-6' style={{borderBottomWidth:'10px',borderTopWidth:'10px',borderLeftWidth:'10px',borderRightWidth:'10px',borderColor:'rgba(0, 0, 0, 0.05)',borderStyle:'solid',backdropFilter:'blur(10px)',borderRadius:'36px'}}>
        <h2 className='text-center'>Create Your Page</h2>
        <div className='form-fields mb-2'> 
          <input type="text" name="page_title" id="page_title" className='form-control tw-border-none focus:tw-ring-0' placeholder='Page Title' onChange={(e)=>setPageTitle(e.target.value.replace(/\s/g, '').toLowerCase())} required/>
          {error?<p className='text-danger'>please give title min(3 characters)</p>:null}
          <textarea type="text" name='post' id='post' placeholder={`Page Descrpition `} className='w-100 p-2 shadow-none my-3 form-control tw-border-none focus:tw-ring-0' rows="6" style={{resize:'none'}} onChange={(e)=>setPageDescription(e.target.value)}/>
        </div>
        <div className='d-flex justify-content-end'>
        <button className='btn tw-bg-[#ffde59] tw-text-black tw-border-none' onClick={createNewpage}>Create</button>
        </div>
      </div>
      }
    </>
  
  )
}

export default PageLists