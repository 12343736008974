import React, { useEffect, useState } from 'react'
import {listAll,ref,getDownloadURL} from 'firebase/storage'
import {storage} from '../../firebase'
import { TfiGallery } from "react-icons/tfi";

const PostImageSelect = ({setImageUrl,setYtUrl}) => {

    const [imageList,setImageList] = useState([]);
    const imageListRef = ref(storage,"/")

    useEffect(()=>{
        listAll(imageListRef).then((response)=>[
            response.items.forEach((item)=>{
                getDownloadURL(item).then((url)=>{
                    setImageList((prev)=>[...prev,url])
                })
            })
        ])
    },[])

    const handleImageClick = (url) =>{
        setImageUrl(url);
    }

  return (
    
    <div className="modal fade" id="secondaryModal" aria-hidden="true" aria-labelledby="secondaryModelLabel" tabIndex='-1' style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="secondaryModalLabel">Please select any one image</h5>
                    <button type="button" className="btn-close" data-bs-target="#exampleModal" data-bs-toggle="modal" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className=' tw-my-2'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text h-100 tw-text-red-600 tw-border-0 bg-body" id="basic-addon1"><TfiGallery/></span>
                        </div>
                        <input type="text" className="p-2 border-top-0 border-end-0 border-start-0 shadow-none form-control" placeholder="Paste your image url here" aria-label="img url" aria-describedby="basic-addon1" onChange={(e)=>setImageUrl(e.target.value)}/>
                    </div>
                    <span className='tw-text-xs tw-italic tw-text-blue-900'>please upload a HD image url for better user experience</span>
                    </div>
                    <div className="tw-inline-flex tw-items-center tw-justify-center tw-w-full">
                        <hr className="tw-w-screen tw-h-px tw-my-8 tw-bg-black tw-border-0"/>
                        <span className="tw-absolute tw-px-3 tw-font-medium tw-text-gray-900 tw--translate-x-1/2 tw-bg-white left-1/2">or</span>
                    </div>
                    <div className="row g-2">
                        {imageList&&imageList.map((url)=>(
                        <div className="col-4" key={url}>
                        <img src={url} alt="Imag5" className="img-thumbnail" style={{ cursor: 'pointer' }}   onClick={()=>handleImageClick(url)} data-bs-target="#exampleModal" data-bs-toggle="modal" data-bs-dismiss="modal" />
                        </div>
                        ))}           
                    </div>
                </div>     
            </div>
        </div>
    </div>


  )
}

export default PostImageSelect