import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logofull.png'
import TypingAnimator from 'react-typing-animator';
import {FaGoogle} from 'react-icons/fa'
import createblog from '../../assets/create_blog.png'
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import HashLoader from "react-spinners/HashLoader";



const Auth = () => {
       
    const push = useNavigate()
    const [loading , setLoading] = useState(false)

    const login = useGoogleLogin({
        onSuccess: async (response) => {
          try {
            const res = await axios.get(
              "https://www.googleapis.com/oauth2/v3/userinfo",
              {
                headers: {
                  Authorization: `Bearer ${response.access_token}`,
                },
              }
            );
            if (res) {
                setLoading(true)
                const email = res.data.email;
                const name = res.data.name;
                const image = res.data.picture
                try{
                    const response = await axios.post('https://server.theatom.blog/api/create',{name:name,email:email,imageURL:image});
                    if(response)
                    {
                        localStorage.setItem('userData',JSON.stringify(response.data));
                        setLoading(false)
                        push(`/dashboard/${response.data.uid}`);
                        // window.location.reload(false);
                    }
                }
                catch(err){
                    console.log(err);
                }
            }
          } catch (error) {
            console.log(error);
          }
        },
      });

      const textArray = ['Create your local news blog today','Create your youtube class blog','Create your portfolio blog','Create your community blog']

  return (
    <>
    <div style={{minHeight:'80vh'}}>
        {loading ? <div  className='tw-flex tw-justify-center tw-items-center tw-flex-col tw-h-screen tw-w-screen'>
      <HashLoader/>
      <p className='fs-6 mt-3'>Please wait while we fetch your page</p>
      </div>:
      <>
      <div className='d-flex justify-content-between '>
        <Link to='/'><img src={logo} alt="logo" className='p-3 tw-w-28'/></Link>
       <div className='lg:tw-flex lg:tw-items-center tw-hidden'>
            <Link className='text-decoration-none text-secondary mx-3'>About</Link>
            <Link className='text-decoration-none text-secondary m-3'>Features</Link>
            <Link className='text-decoration-none text-secondary mx-3'>Stories</Link>
        </div>
        </div>
    <div className='container-fluid d-flex align-items-center flex-column' style={{marginTop:'8em'}}>
            <img src={createblog} alt="header" className='lg:tw-w-[45%] tw-w-[400px]'/>
            <h1 className='text-center mt-1  tw-text-[28px]  tw-tracking-[8px] lg:tw-text-[36px] lg:tw-tracking-[20px]'>It's as easy as Whatsapp</h1>
    </div>
        <div className='lg:tw-flex lg:tw-justify-center lg:tw-items-center lg:tw-mt-5 lg:tw-flex-col tw-hidden'>
            <div className='w-50 shadow-lg' style={{borderBottomWidth:'10px',borderTopWidth:'10px',borderLeftWidth:'10px',borderRightWidth:'10px',borderColor:'rgba(0, 0, 0, 0.05)',borderStyle:'solid',backdropFilter:'blur(10px)',borderRadius:'36px'}}>
            <div className='d-flex justify-content-between align-items-center bg-white p-3' style={{borderRadius:'28px'}}>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src={logo} alt="slogo" width='50' className='tw-mr-4'/>
                <TypingAnimator textArray={textArray}
                    loop
                    height='20px'
                    typingSpeed={80}
                    delaySpeed={1500}
                    backspace
                    cursorColor="#999999"
                    textColor='#999999'
                    fontSize='16px'
                    />
                </div>
                <div>
                    <button className='btn' style={{background:'#ffde59'}} onClick={()=>login()}>Start with<FaGoogle className='mx-2'/></button>
                </div>
            </div>
            </div>
        </div>
        <div className='tw-m-3 tw-flex tw-justify-center tw-items-center lg:tw-hidden '>
        <div style={{borderBottomWidth:'5px',borderTopWidth:'5px',borderLeftWidth:'5px',borderRightWidth:'5px',borderColor:'rgba(0, 0, 0, 0.05)',borderStyle:'solid',backdropFilter:'blur(10px)',borderRadius:'36px'}}>
          <button className='btn tw-rounded-[36px]' style={{background:'#ffde59'}} onClick={()=>login()}>Start with<FaGoogle className='mx-2'/></button>
          </div>
        </div></>}
    </div>
    </>
  )
}

export default Auth