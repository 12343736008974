import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import {FaYoutube} from 'react-icons/fa'
import {PiTextTBold} from 'react-icons/pi'
import {RiSendPlane2Fill} from 'react-icons/ri'
import { TfiGallery } from "react-icons/tfi";
import {FiLink2} from 'react-icons/fi'
import axios from 'axios';




const PostFooter = ({toggle,setPostUrl,submitPost,schedulePost,setYtUrl}) => {

  const [youtube,showYoutube] = useState(false)
  const [media,setMedia] = useState(false)
  const [schedule,setSecdule] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const pathArray = window.location.pathname.split('/');
  const pageId = pathArray[pathArray.length-1];
  const [title,setTitle] = useState('')

  useEffect(()=>{
    const fetchPageTitle = async () =>{
      try {
        const res = await axios.get(`https://server.theatom.blog/api/getPageTitle/${pageId}`);
        if(res){
          setTitle(res.data.page_title)
        }
      } catch (error) {
        console.log(error)  
      }
    }


    fetchPageTitle();
},[pageId])




  const handleMedia = ()=>{
    setMedia(!media)
  }

  const handleYoutube = ()=>{
    showYoutube(!youtube)
  }


  // const handleSchedule = ()=>{
  //   setSecdule(!schedule)
  // }

   

  return (
    <>   
    <div className='d-flex' style={{background:'#F2F2F2'}}>
      <div style={{background:'#F2F2F2',borderRadius:'5px',marginRight:'5px'}}>
        <button className='btn btn-sm border-0' onClick={toggle}><PiTextTBold style={{color:"#999999"}}/></button>
          <a  className='btn btn-sm border-0 ' data-bs-toggle="modal" href="#secondaryModal" data-bs-dismiss="modal"><TfiGallery style={{color:"#999999",fontSize:'13px'}}/></a>
            <button className='btn btn-sm border-0' onClick={handleMedia}><FiLink2 style={{color:"#999999"}}/></button>
            <button className='btn btn-sm border-0' onClick={handleYoutube}><FaYoutube style={{color:"#999999"}}/></button>
        </div>
        {/* <div style={{background:'#F2F2F2',borderRadius:'5px',marginRight:'5px'}}>
        <button className='btn btn-sm border-0' onClick={handleSchedule}><PiTimerFill style={{color:"#999999"}}/></button>
        </div> */}
    </div> 
    <div className='d-flex align-items-center '>
      <h6 className='mx-2 mt-2' style={{fontSize:'13px',color:"#666666"}}>Posting to: {title}</h6>
      <button type="button" className="btn" onClick={submitPost} style={{background:'#ffde59',borderRadius:'12px'}}><RiSendPlane2Fill class='fs-5 text-center'color='white'/></button></div>
    <div className='w-100'>
    { media&& 
    // <input type="text" name='url' id='url' placeholder='Paste your URL here' className='w-100 p-2 border-top-0 border-end-0 border-start-0 shadow-none my-3 form-control' onChange={(e)=>setPostUrl(e.target.value)}/>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text h-100 tw-text-red-600 tw-border-0 bg-body mx-1" id="basic-addon1"><FiLink2/></span>
            </div>
            <input type="text" class="p-2 border-top-0 border-end-0 border-start-0 shadow-none form-control" placeholder="Paste your url here" aria-label="url" aria-describedby="basic-addon1" onChange={(e)=>setPostUrl(e.target.value)}/>
          </div>
    }
    { youtube&& 
    // <input type="text" name='url' id='Yturl' placeholder='Paste your YT url here' className='w-100 p-2 border-top-0 border-end-0 border-start-0 shadow-none my-3 form-control' onChange={(e)=>setYtUrl(e.target.value)}/>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text h-100 tw-text-red-600 tw-border-0 bg-body mx-1" id="basic-addon1"><FaYoutube/></span>
          </div>
          <input type="text" class="p-2 border-top-0 border-end-0 border-start-0 shadow-none form-control" placeholder="Paste your YT url here" aria-label="Yt url" aria-describedby="basic-addon1" onChange={(e)=>setYtUrl(e.target.value)}/>
      </div>

    }

    {/* {schedule&&
    <div className='schedule'>
      <hr />
      <p className='fs-6 fw-medium mt-1' style={{color:'#666666'}}>Schedule The Post</p>
      <div className="d-flex justify-content-between">
    <DatePicker
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
          className='p-1 text-black'
          showTimeSelect
          selected={startDate} onChange={(date) => setStartDate(date)}
        />
        <button className='btn btn-primary btn-sm border-0' onClick={()=>schedulePost(startDate)} data-bs-dismiss="modal" style={{background:'#ffde59'}}>Schedule</button>
        </div>
    </div>
    } */}

    </div>
    </>
  )
}

export default PostFooter