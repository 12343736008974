import React, { useEffect, useState } from 'react'
import EndUserProfile from './EndUserProfile'
import EndUserPostSection from './EndUserPostSection'
import EndUserHero from './EndUserHero'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import EndUserPageDetails from './EndUserPageDetails'
import logo from '../../assets/logofull.png'

const EndUser = () => {

    const {pageName} = useParams();
    const [pageId,setPageId] = useState()
    const [hero,setHero] = useState(false);
    const [profile,setProfile] = useState(false);

    
    const fetchPageId = async ()=>{
        const res = await axios.get(`https://server.theatom.blog/api/getPageId/${pageName}`)
        setPageId(res.data.pid)
    }

    const fetchData = async ()=>{
        const res = await axios.get(`https://server.theatom.blog/api/getPageSettings/${pageId}`)
        if(res.data){
            setHero(res.data.hero === '1')
            setProfile(res.data.profile === '1')
        }
      }

    useEffect(()=>{
        fetchPageId();
        fetchData();
    },[pageName,pageId])


  return (
    <div className='cotainer-fluid py-3 tw-relative'>
       <div className='tw-p-2 tw-bg-[#ffde59] tw-rounded-xl tw-shadow-xl tw-border-none tw-z-10 tw-fixed tw-right-2 tw-bottom-10'>
          <a className='tw-text-sm tw-text-black tw-cursor-pointer' href='/' target='_blank' style={{fontFamily:'poppins',textDecoration:'none'}}>Made with
          <img src={logo} className='tw-w-6 tw-mx-2'/>
          </a>
        </div>
      {/* <img src={logo} alt="logo" className='' style={{width:'70px'}}/> */}

    <div className="row">
      {/* {hero && <div className="col-lg-9 col-md-8 col-sm-12">
            <EndUserHero/>
        </div>}
      {profile && <div className="col-lg-3 col-md-4 col-sm-12">
            <EndUserProfile/>
        </div>} */}
        
        <div className='col-12 mt-2'>
            <EndUserPageDetails/>
        </div>
        <div className='col-12 mt-2'>
            <EndUserPostSection/>
        </div>
    </div>
</div>
  )
}

export default EndUser