import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player'
import {MdOutlineMoreVert} from 'react-icons/md'
import {FaExternalLinkAlt} from 'react-icons/fa'


const CardThree = () => {

    const [data,setData] = useState([]);
    const [settings,setSettings] = useState(null)
    const [footer,showFooter] = useState(false)
    const [flatCard,setFlatCard] = useState(false)
    const {pageId} = useParams();


    const fetchData = async () => {
      try {
        const res = await axios.get(`https://server.theatom.blog/api/posts/${pageId}`);
        setData(res.data);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };
  
    const fetchSettings = async () => {
      try {
        const res = await axios.get(`https://server.theatom.blog/api/getSettings/${pageId}`);
        setSettings(res.data);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching settings:', error);
      }
    };

    const fetchPageSettings = async () =>{
      try {
        const res = await axios.get(`https://server.theatom.blog/api/getPageSettings/${pageId}`);
        if(res.data){
          showFooter(res.data.time_stamp === '1')
          setFlatCard(res.data.flat_card === '1')
        }
      } catch (error) {
        console.log(error)
      }
    }


    useEffect(() => {
      fetchData();
      fetchSettings();
      fetchPageSettings();
  
     
      const intervalId = setInterval(() => {
        fetchData();
        fetchSettings();
      }, 1000); 
  

      return () => clearInterval(intervalId);
    }, [pageId]);







    const isHighlighted = (postId) => {
        return   settings &&  Array.isArray(settings) && settings.some(
          (setting) => setting.post_id === postId && setting.card_highlight === '1'
        );
      };

 
    const pinnedCards = Array.isArray(data) && Array.isArray(settings)
    ? data.filter((item) =>
        settings.some(
          (setting) => setting.post_id === item.post_id && setting.card_pinned === '1'
        )
      )
    : [];

  const nonPinnedCards = Array.isArray(data) && Array.isArray(settings)
    ? data.filter((item) =>
        !settings.some(
          (setting) => setting.post_id === item.post_id && setting.card_pinned === '1'
        )
      )
    : [];

  // Concatenate pinned cards and non-pinned cards to maintain the order, only if settings is an array
  const sortedData = Array.isArray(settings) ? [ ...nonPinnedCards,...pinnedCards,] :[];
    

    const getTimeAgo = (timestamp) =>{
        const now = new Date();
        const postTime = new Date(timestamp);
        const timediiference = now - postTime;
        const minutesAgo = Math.floor(timediiference/(1000 * 60))
        
        if (minutesAgo < 60) {
            return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
        } else if (minutesAgo < 1440) {
            const hoursAgo = Math.floor(minutesAgo / 60);
            return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
        } else {
            const daysAgo = Math.floor(minutesAgo / 1440);
            return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`;
        }
      }

      const isPinned = (postId) => {
        return (
          settings && Array.isArray(settings) && 
          settings.some(
            (setting) => setting.post_id === postId && setting.card_pinned === '1'
          )
        );
      };

      const handlePinClick = useCallback(async (postId) => {

        if(settings.message){
          try {
            await axios.post('https://server.theatom.blog/api/cardSettings', {
              pid: pageId,
              post_id: postId,
              pinned: true,
              highlighted:null,
            });
          } catch (error) {
            console.error('Error updating pin status:', error);
          }
        }

        const isAlreadyPinned = isPinned(postId);
        try {
          await axios.post('https://server.theatom.blog/api/cardSettings', {
            pid: pageId,
            post_id: postId,
            pinned: !isAlreadyPinned,
            highlighted: isHighlighted(postId),
          });
        } catch (error) {
          console.error('Error updating pin status:', error);
        }
      }, [settings,pageId,isHighlighted,isPinned]);
      


      const handleHighlightClick = useCallback(async (postId) => {

        if(settings.message){
          try {
            await axios.post('https://server.theatom.blog/api/cardSettings', {
              pid: pageId,
              post_id: postId,
              pinned: null,
              highlighted:true,
            });
          } catch (error) {
            console.error('Error updating pin status:', error);
          }
        }

        const isAlreadyHighlighted = isHighlighted(postId);
    
        try {
          await axios.post('https://server.theatom.blog/api/cardSettings', {
            pid: pageId,
            post_id: postId,
            pinned: isPinned(postId),
            highlighted: !isAlreadyHighlighted,
          });
        } catch (error) {
          console.error('Error updating highlight status:', error);
        }
      }, [settings,pageId,isHighlighted,isPinned]);


      const handleHero = useCallback(async (postId)=>{
        try {
          await axios.post('https://server.theatom.blog/api/setHero',{
            pid:pageId,
            post_id:postId
          })
        } catch (error) {
          console.log(error)
        }

      },[pageId])


      const handleDelete = useCallback(async (postId)=>{
        try {
          const deletePost = await axios.delete(`https://server.theatom.blog/api/deletePost/${postId}`)

          if(deletePost){
            console.log(deletePost.data)
          }

        } catch (error) {
          console.log(error)
        }


      })
      

   if(sortedData.length>0){   
  return (
    <>
    {   sortedData&&sortedData.length>0 ?
    sortedData.reverse().map((item)=>(
        <div key={item.post_id} className='col'>
        {item.image_url || item.yt_url ? 
        <div className={`tw-relative card tw-border-none tw-mt-3 tw-rounded-3xl h-100`}>
           <div className="position-absolute" style={{right:'3px',top:'5px'}}>
            <div className="btn-group z-3">
                <button type="button" className='border-0 bg-transparent' data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <MdOutlineMoreVert className='fs-4 text-secondary' />
                </button>
                <div className="dropdown-menu">
                    <li className="dropdown-item" onClick={() => handlePinClick(item.post_id)}>{isPinned(item.post_id) ? 'Unpin' : 'Pin'}</li>
                    {(item.post_title || item.post_body)?<li className="dropdown-item" onClick={() => handleHighlightClick(item.post_id)}>{isHighlighted(item.post_id)?'Unhighlight':'Highlight'}</li>:null}
                    {/* <li className="dropdown-item"onClick={()=>handleHero(item.post_id)}>Hero</li> */}
                    <li className="dropdown-item" onClick={()=>handleDelete(item.post_id)}>Delete</li>   
                </div>
            </div>
        </div>
        {(item.image_url || item.yt_url) && 
             <div className='tw-min-h-[24rem] tw-overflow-hidden tw-rounded-3xl h-100'>
           { item.image_url ? <img src={item.image_url} alt=" random imgee" class="tw-w-full tw-h-full tw-object-cover tw-object-center tw-rounded-lg tw-shadow-md"/>: <ReactPlayer url={item.yt_url} className={`absolute bg-neutral-900 inset-0 transition-opacity`} width="100%" height="100%" controls/>}
             </div>   
        }
       { (item.post_title || item.post_body) ? <div class="tw-relative  tw-pr-6 tw--mt-16">
       <div class={`${!isHighlighted(item.post_id)?'tw-bg-white':'tw-bg-neutral-900'} tw-p-4 tw-rounded-3xl tw-rounded-tl-none ${flatCard?'shadow-none':'tw-shadow-lg'}`}>
        <h2 class= {`tw-block lg:tw-text-xl sm:tw-text-lg ${!isHighlighted(item.post_id)?'tw-text-neutral-900':'tw-text-white'}`} style={{fontFamily:'poppins'}}>{item.post_title}</h2>
        <span className="tw-block  tw-text-sm lg:tw-text-[16px] tw-mt-3 tw-mb-3">
            <span className={`tw-leading-5 ${!isHighlighted(item.post_id)?'tw-text-neutral-500':'tw-text-white'}`} style={{fontFamily:'poppins'}}>{item.post_body}</span>
          </span>
           <div className="tw-flex tw-items-end tw-justify-between tw-mt-auto">
           { footer ?<span className='tw-text-sm tw-text-neutral-400'>posted: {getTimeAgo(item.post_timestamp)}</span>:null}
              {item.post_url && <a href={item.post_url} className='tw-mr-4 tw-text-neutral-400'><FaExternalLinkAlt/></a>}
        </div>
      </div>
     </div>:null}
    </div>:
        <div class={`card ${isHighlighted(item.post_id)?'tw-bg-neutral-900':'tw-bg-white'} tw-border-none tw-mt-3 tw-rounded-3xl ${flatCard?' shadow-none ':'tw-shadow-lg'} h-100 tw-relative`}>
              <div className="position-absolute" style={{right:'3px',top:'5px'}}>
            <div className="btn-group z-3">
                <button type="button" className='border-0 bg-transparent' data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <MdOutlineMoreVert className='fs-4 text-secondary' />
                </button>
                <div className="dropdown-menu">
                    <li className="dropdown-item" onClick={() => handlePinClick(item.post_id)}>{isPinned(item.post_id) ? 'Unpin' : 'Pin'}</li>
                    <li className="dropdown-item" onClick={() => handleHighlightClick(item.post_id)}>{isHighlighted(item.post_id)?'Unhighlight':'Highlight'}</li>
                    {/* <li className="dropdown-item"onClick={()=>handleHero(item.post_id)}>Hero</li> */}
                    <li className="dropdown-item" onClick={()=>handleDelete(item.post_id)}>Delete</li>   
                </div>
            </div>
        </div>
            <div className=' tw-flex tw-justify-center tw-items-center tw-flex-col tw-h-full tw-p-4'>
            <h2 class={`tw-block lg:tw-text-5xl sm:tw-text-lg ${!isHighlighted(item.post_id)?'tw-text-neutral-900':'tw-text-white'}`} style={{fontFamily:'poppins'}}>
                {item.post_title}
            </h2>
            <span class={`tw-leading-5 ${!isHighlighted(item.post_id)?'tw-text-neutral-500':'tw-text-white'} tw-block  tw-text-sm lg:tw-text-[16px] tw-mt-3`} style={{fontFamily:'poppins'}}>
                {item.post_body}
            </span>
            </div>
            <div className="tw-flex tw-items-end tw-justify-between tw-mt-auto tw-p-4">
           { footer ?<span className='tw-text-sm tw-text-neutral-400'>posted: {getTimeAgo(item.post_timestamp)}</span>:null}
              {item.post_url && <a href={item.post_url} className='tw-mr-4 tw-text-neutral-400'><FaExternalLinkAlt/></a>}
        </div>
    </div>
        }
    </div>
    )):
    <div className='d-flex justify-content-center align-items-center flex-column'>
        <h5>Create your first post now</h5>
        <button type="button" className="btn btn-sm tw-bg-[#ffde59] tw-text-black tw-border-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Post Now
        </button>
    </div>
        
    }
    </>
  )
}
    return (
        <>
        {   data&&data.length>0 ?
        data.map((item)=>(
            <div key={item.post_id} className='col'>
            {item.image_url || item.yt_url ? 
            <div className={`tw-relative card tw-border-none tw-mt-3 tw-rounded-3xl h-100`}>
               <div className="position-absolute" style={{right:'3px',top:'5px'}}>
                <div className="btn-group z-3">
                    <button type="button" className='border-0 bg-transparent' data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <MdOutlineMoreVert className='fs-4 text-secondary' />
                    </button>
                    <div className="dropdown-menu">
                        <li className="dropdown-item" onClick={() => handlePinClick(item.post_id)}>{isPinned(item.post_id) ? 'Unpin' : 'Pin'}</li>
                        {(item.post_title || item.post_body)?<li className="dropdown-item" onClick={() => handleHighlightClick(item.post_id)}>{isHighlighted(item.post_id)?'Unhighlight':'Highlight'}</li>:null}
                        {/* <li className="dropdown-item"onClick={()=>handleHero(item.post_id)}>Hero</li> */}
                        <li className="dropdown-item" onClick={()=>handleDelete(item.post_id)}>Delete</li>   
                    </div>
                </div>
            </div>
            {(item.image_url || item.yt_url) && 
                 <div className='tw-min-h-[24rem] tw-overflow-hidden tw-rounded-3xl h-100'>
               { item.image_url ? <img src={item.image_url} alt=" random imgee" class="tw-w-full tw-h-full tw-object-cover tw-object-center tw-rounded-lg tw-shadow-md"/>: <ReactPlayer url={item.yt_url} className={`absolute bg-neutral-900 inset-0 transition-opacity`} width="100%" height="100%" controls/>}
                 </div>   
            }
           { (item.post_title || item.post_body) ? <div class="tw-relative  tw-pr-6 tw--mt-16">
           <div class={`${!isHighlighted(item.post_id)?'tw-bg-white':'tw-bg-neutral-900'} tw-p-4 tw-rounded-3xl tw-rounded-tl-none ${flatCard?'shadow-none':'tw-shadow-lg'}`}>
            <h2 class= {`tw-block lg:tw-text-xl sm:tw-text-lg ${!isHighlighted(item.post_id)?'tw-text-neutral-900':'tw-text-white'}`} style={{fontFamily:'poppins'}}>{item.post_title}</h2>
            <span className="tw-block  tw-text-sm lg:tw-text-[16px] tw-mt-3 tw-mb-3">
                <span className={`tw-leading-5 ${!isHighlighted(item.post_id)?'tw-text-neutral-500':'tw-text-white'}`} style={{fontFamily:'poppins'}}>{item.post_body}</span>
              </span>
              <div className="tw-flex tw-items-end tw-justify-between tw-mt-auto">
           { footer ?<span className='tw-text-sm tw-text-neutral-400'>posted: {getTimeAgo(item.post_timestamp)}</span>:null}
              {item.post_url && <a href={item.post_url} className='tw-mr-4 tw-text-neutral-400'><FaExternalLinkAlt/></a>}
        </div>
          </div>
         </div>:null}
        </div>:
            <div class={`card ${isHighlighted(item.post_id)?'tw-bg-neutral-900':'tw-bg-white'} tw-border-none tw-mt-3 tw-rounded-3xl ${flatCard?' shadow-none ':'tw-shadow-lg'} h-100 tw-relative`}>
                  <div className="position-absolute" style={{right:'3px',top:'5px'}}>
                <div className="btn-group z-3">
                    <button type="button" className='border-0 bg-transparent' data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <MdOutlineMoreVert className='fs-4 text-secondary' />
                    </button>
                    <div className="dropdown-menu">
                        <li className="dropdown-item" onClick={() => handlePinClick(item.post_id)}>{isPinned(item.post_id) ? 'Unpin' : 'Pin'}</li>
                        <li className="dropdown-item" onClick={() => handleHighlightClick(item.post_id)}>{isHighlighted(item.post_id)?'Unhighlight':'Highlight'}</li>
                        {/* <li className="dropdown-item"onClick={()=>handleHero(item.post_id)}>Hero</li> */}
                        <li className="dropdown-item" onClick={()=>handleDelete(item.post_id)}>Delete</li>   
                    </div>
                </div>
            </div>
                <div className=' tw-flex tw-justify-center tw-items-center tw-flex-col tw-h-full tw-p-4'>
                <h2 class={`tw-block lg:tw-text-5xl sm:tw-text-lg ${!isHighlighted(item.post_id)?'tw-text-neutral-900':'tw-text-white'}`} style={{fontFamily:'poppins'}}>
                    {item.post_title}
                </h2>
                <span class={`tw-leading-5 ${!isHighlighted(item.post_id)?'tw-text-neutral-500':'tw-text-white'} tw-block  tw-text-sm lg:tw-text-[16px] tw-mt-3`} style={{fontFamily:'poppins'}}>
                    {item.post_body}
                </span>
                </div>
                <div className="tw-flex tw-items-end tw-justify-between tw-mt-auto tw-p-4">
           { footer ?<span className='tw-text-sm tw-text-neutral-400'>posted: {getTimeAgo(item.post_timestamp)}</span>:null}
              {item.post_url && <a href={item.post_url} className='tw-mr-4 tw-text-neutral-400'><FaExternalLinkAlt/></a>}
        </div>
        </div>
            }
        </div>
        )):
        <div className='d-flex justify-content-center align-items-center flex-column' style={{width:'100%',height:'90vh'}}>
            <h5>Create your first post now</h5>
            <button type="button" className="btn btn-sm tw-bg-[#ffde59] tw-text-black tw-border-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Post Now
            </button>
        </div>  
        }
        </>
      )

}

export default CardThree