import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/logofull.png'

const CreateProfile = () => {

    const { pageId } = useParams();
    const [pageTitle,setPageTitle] = useState(null)
    const [pageDescription,setPageDescription] = useState(null)
    const push = useNavigate()
    // const [image, setImage] = useState(null);
    // const [info, setInfo] = useState(null);
    // const [url, setUrl] = useState(null);
    // const [yturl, setYturl] = useState(null);
    // const [instaurl, setInstaurl] = useState(null);
    // const [fburl, setFburl] = useState(null);
    // const [data, setData] = useState(null);
    // const push = useNavigate();

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const res = await axios.get(`https://server.theatom.blog/api/getProfile/${pageId}`);
    //             if (res) {
    //                 setData(res.data);
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    //     fetchData();
    // }, [pageId]);

    // const handleImageChange = (e) => {
    //     setImage(e.target.files[0]);
    // }

    // const handleUpload = async (e) => {
    //     e.preventDefault();
    //     const formData = new FormData();
    //     formData.append('image', image);
    //     formData.append('info', info?info:data.profile_info); 
    //     formData.append('url', url?url:data.profile_url); 
    //     formData.append('yturl', yturl?yturl:data.profile_yturl); 
    //     formData.append('instaurl', instaurl?instaurl:data.profile_insta); 
    //     formData.append('fburl', fburl?fburl:data.profile_fburl); 
    //     formData.append('pid', pageId);

    //     try {
    //         const res = await axios.post('https://server.theatom.blog/api/createProfile', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });
    //         if (res) {
    //             push(`/post/${pageId}`);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const updatePage = async () =>{
        const res = await axios.post('https://server.theatom.blog/api/updatePage',{pid:pageId,pageTitle,pageDescription})
        if(res){
            console.log(res.data);
            push(`/post/${pageId}`)
        }
      }
      useEffect(()=>{
        const fetchPageTitle = async () =>{
            try {
              const res = await axios.get(`https://server.theatom.blog/api/getPageTitle/${pageId}`);
              if(res){
                setPageTitle(res.data.page_title)
                setPageDescription(res.data.page_desc)
              }
            } catch (error) {
              console.log(error)  
            }
          }


          fetchPageTitle();

    },[pageId])


    return (
        // <div className="container mt-5">
        //     <div className="row justify-content-center">
        //         <div className="col-md-6">
        //             <h2 className='text-danger text-center'>Profile</h2>
        //             <form className='bg-white p-4 rounded-3 shadow-lg'>
        //             <div className="form-group">
        //                     <div className="image-upload-container">
        //                         <input
        //                             type="file"
        //                             id="profilePicture"
        //                             name="profilePicture"
        //                             onChange={handleImageChange}
        //                             accept="image/*"
        //                             className="file-input"
        //                         />
        //                    <img src={` https://server.theatom.blog/${data?.profile_image}`} alt='profile' className='rounded-circle object-fit-cover' style={{width:'50px',height:'50px'}}/>
        //                     </div>
        //                 </div>
        //                 <div className="form-group">
        //                     <label htmlFor="profileInfo">Profile Info</label>
        //                     <textarea
        //                         className="form-control"
        //                         id="profileInfo"
        //                         name="profileInfo"
        //                         rows="4"
        //                         style={{ resize: 'none' }}
        //                         value={info || (data && data.profile_info)}
        //                         onChange={(e) => setInfo(e.target.value)}
        //                     ></textarea>
        //                 </div>
        //                 <div className="form-group">
        //                     <label htmlFor="ytUrl">YouTube URL</label>
        //                     <input
        //                         type="text"
        //                         className="form-control"
        //                         id="ytUrl"
        //                         name="ytUrl"
        //                         value={yturl || (data && data.profile_yturl)}
        //                         onChange={(e) => setYturl(e.target.value)}
        //                     />
        //                 </div>
        //                 <div className="form-group">
        //                     <label htmlFor="websiteUrl">Website URL</label>
        //                     <input
        //                         type="text"
        //                         className="form-control"
        //                         id="websiteUrl"
        //                         name="websiteUrl"
        //                         value={url || (data && data.profile_url)}
        //                         onChange={(e) => setUrl(e.target.value)}
        //                     />
        //                 </div>
        //                 <div className="form-group">
        //                     <label htmlFor="instaUrl">Instagram URL</label>
        //                     <input
        //                         type="text"
        //                         className="form-control"
        //                         id="instaUrl"
        //                         name="instaUrl"
        //                         value={instaurl || (data && data.profile_insta)}
        //                         onChange={(e) => setInstaurl(e.target.value)}
        //                     />
        //                 </div>
        //                 <div className="form-group">
        //                     <label htmlFor="fbUrl">Facebook URL</label>
        //                     <input
        //                         type="text"
        //                         className="form-control"
        //                         id="fbUrl"
        //                         name="fbUrl"
        //                         value={fburl || (data && data.profile_fburl)}
        //                         onChange={(e) => setFburl(e.target.value)}
        //                     />
        //                 </div>
        //                 <button type="submit" className="btn btn-danger mt-3" onClick={handleUpload}>
        //                     Save
        //                 </button>
        //             </form>
        //         </div>
        //     </div>
        // </div>
        <>
        <img src={logo} alt="logo" className='p-3 tw-w-28'/>
        <div className='d-flex justify-content-center align-items-center' style={{height:'80vh'}}>
            <div className='col shadow-lg p-4 col-12 col-md-6 col-lg-6'  style={{borderBottomWidth:'10px',borderTopWidth:'10px',borderLeftWidth:'10px',borderRightWidth:'10px',borderColor:'rgba(0, 0, 0, 0.05)',borderStyle:'solid',backdropFilter:'blur(10px)',borderRadius:'36px'}}>
                <h2 className='text-center'>Update your page</h2>
                <div className='form-fields mb-2'> 
                <input type="text" name="page_title" id="page_title" className='form-control tw-border-none focus:tw-ring-0' placeholder='Page Title' onChange={(e)=>setPageTitle(e.target.value.replace(/\s/g, '').toLowerCase())} required value={pageTitle}/>
                <textarea type="text" name='post' id='post' placeholder={`Page Descrpition `} className='w-100 p-2 shadow-none my-3 form-control tw-border-none focus:tw-ring-0' rows="6" style={{resize:'none'}} onChange={(e)=>setPageDescription(e.target.value)} value={pageDescription}/>
                </div>
                <div className='d-flex justify-content-end'>
                <button className='btn tw-bg-[#ffde59] tw-text-black tw-border-none' onClick={updatePage}>Update</button>
                </div>
             </div>
        </div>
        </>


    )
}

export default CreateProfile;
