import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const EndUserPageDetails = () => {

    const {pageName} = useParams()
    const [title,setTitle] = useState();
    const [description,setDescription] = useState()
    const [pageId,setPageId] = useState(null)

  useEffect(()=>{
    const fetchPageId = async ()=>{
      const res = await axios.get(`https://server.theatom.blog/api/getPageId/${pageName}`)
      setPageId(res.data.pid)
    }

  
    const fetchPageTitle = async () =>{
      try {
        const res = await axios.get(`https://server.theatom.blog/api/getPageTitle/${pageId}`);
        if(res){
          setTitle(res.data.page_title)
          document.title = res.data.page_title ? res.data.page_title : 'Atom blog' 
          setDescription(res.data.page_desc)
        }
      } catch (error) {
        console.log(error)  
      }
    }

    fetchPageId();
    fetchPageTitle();
  },[pageName,pageId])



  return (
    <div
    className={`nc-Section-Heading tw-relative tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-end tw-justify-between tw-mb-10 md:tw-mb-12 tw-text-neutral-900 dark:tw-text-neutral-50`}
  >
    <div
      className={
        "tw-w-full"
      }
    >
      <h2
        className={`tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-font-semibold tw-text-black`} style={{ fontFamily: 'poppins' }}
      >
        {title}
      </h2>
      <span className="tw-mt-2 md:tw-mt-3 tw-font-normal tw-block tw-text-base sm:tw-text-xl tw-text-neutral-500 dark:tw-text-neutral-400" style={{ fontFamily: 'poppins' }}>
        {description ? description : 'Embark on a journey through the brilliant mind of your favorite blogger, uncovering their most captivating discoveries.'
        }
      </span>
    </div>
    {/* <div className="tw-absolute tw-left-10 tw-top-8 tw-bg-[#ef233c] tw-w-20 tw-h-20 tw-rounded-full tw-filter tw-blur-3xl tw-opacity-100 lg:tw-w-20 lg:tw-h-20"></div> */}
  </div>
  
  )
}

export default EndUserPageDetails