import React from 'react'
import {IoMdAdd} from 'react-icons/io'
import PostBoxModal from './PostBoxModal'

const PostBox = () => {
  
  return (
 <>
    <a type="button" className="btn mx-2 fw-bold shadow-lg" data-bs-toggle="modal" href="#exampleModal" style={{background:'#ffde59',borderRadius:'15px',boxShadow: '5px 10px #888888;'}}>
      <IoMdAdd className='fs-5'/>
    </a>
    <PostBoxModal />
    </>
  )
}

export default PostBox